<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="3">
      <v-card :loading="loading" class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-chip
              color="#104f94"
              class="v-chip-light-bg text-xl text-white font-weight-semibold  text-uppercase"
          >Informations sur le Colis</v-chip>
          <v-divider></v-divider>
          <table class="table table-borderless">
            <tbody>
              <tr class="content">
                <td class="font-weight-bold custom-qrcode"><v-img width="120" :src="orderdetail.qrcode"></v-img></td>
              </tr>
              <tr class="content">
                <td class="font-weight-bold" style="font-size: 15px">
                  Colis N° <small>{{orderdetail.id}}</small> du <small>{{orderdetail.created_at}}</small> <br/>
                  Départ: <small>{{orderdetail.sender.town}}</small> <br/>
                  Arrivée: <small>{{orderdetail.receiver.town}}</small> <br/>
                  N° Fact.: <small>{{ orderdetail.num_facture }}</small><br/>
                  Code de tracking: <small>{{orderdetail.tracking_code}}</small><br/>
                  Ref. paiement: <small>{{orderdetail.paymentreference}}</small><br/>
                  Mode de livraison: <small>{{orderdetail.deliverymode}}</small><br/>
                  Status: <small>{{orderdetail.status}}</small><br/>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-title>

        <v-card-text>
          <h4 class="text-xl font-weight-semibold mb-2">
            Expéditeur
          </h4>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
                dense
                class="px-0 mb-n2"
            >
              <span class="text--secondary">Nom Complet: {{ orderdetail.sender.firstname }} <br> Pays: {{ orderdetail.sender.country.iso }} <br> Tél: {{ orderdetail.sender.phonenumber }} <br> Ville:{{ orderdetail.sender.town }} <br> Adresse: {{ orderdetail.sender.address }} <br> BP: {{ orderdetail.sender.postalcode }}</span>
            </v-list-item>
          </v-list>

        </v-card-text>

        <v-card-text>
          <h4 class="text-xl font-weight-semibold mb-2">
            Recepteur
          </h4>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
                dense
                class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2"></span>
              <span class="text--secondary">Nom Complet: {{ orderdetail.receiver.firstname }} <br> Pays: {{ orderdetail.receiver.country.iso }} <br> Tél: {{ orderdetail.receiver.phonenumber }} <br> Ville:{{ orderdetail.receiver.town }} <br> Adresse: {{ orderdetail.receiver.address }} <br> BP: {{ orderdetail.receiver.postalcode }}</span>
            </v-list-item>
          </v-list>

        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
              block
              small
              color="primary"
              class="me-3"
              @click="dialog = true"
          >
            <v-icon>mdi-pencil</v-icon>
            Editer
          </v-btn>

          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <!-- <AffiliateDetail
                :statues="statues"
                :instanceaffiliate="affiliate"/>  -->
          </v-dialog>
        </v-card-actions>
      </v-card>


    </v-col>
    <v-col lg="9">
      <v-card-text>
        <template>
          <v-card color="basil">
            <v-card-title class="text-center justify-center py-6">
              <h6 class="font-weight-bold text-h4 basil--text">
                Livreurs
              </h6>
            </v-card-title>

            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
            >
              <v-tab
                  :key="'affiliation'"
              >
                Tous
              </v-tab>

              <v-tab
                  :key="'disponibility'"
              >
                Disponibles
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                  :key="'affiliation'"
              >
                <v-row
                    color="basil"
                    flat
                >
                  <Affiliation
                      class="p-8"
                      :detailCourse="true"
                      :order="orderdetail" />
                </v-row>
              </v-tab-item>
              <v-tab-item
                  :key="'disponibility'"
              >
                <v-row
                    color="basil"
                    flat
                >
                  <Affiliation-disponible :departure="orderdetail.sender.town" :arrival="orderdetail.receiver.town" :detailCourse="true" :order="orderdetail" />
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </template>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>
import {Drequest} from "../plugins/Drequest";
import Affiliation from "./settings/Affiliation";
import AffiliationDisponible from "./settings/AffiliationDisponible";

export default {
  name: "FicheColis",
  components: {
    Affiliation,
    AffiliationDisponible
  },
  data: () => ({
    tab: null,
    loading: false,
    dialog: false,
    orderdetail: ""
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      console.log(this.$route.params.id)
      Drequest.api("dcollection")
          .data({
            "detail.order": this.$route.params.id
          })
          .raw((response) => {
            console.log(response.order.order)
            this.orderdetail = response.order.order;
            // this.close();
          })

    },
  }
}
</script>

<style scoped>
@import "../assets/css/bootstrap5.min.css";
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');
body {
  font-family: 'Maven Pro', sans-serif;
  background-color: #f12369
}

hr {
  color: rgba(101, 96, 96, 0.31);
  margin-top: 5px;
  margin-bottom: 5px
}

.add {
  color: #827f7f;
  text-transform: uppercase;
  font-size: 12px
}
.colorCredit {
  color: #0d6efc;
}
.content {
  font-size: 14px
}

#footer {
  background: #404040;
  padding: 0 0 5px 0;
  color: #fff;
  font-size: 14px
}

#footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #fff
}

.custom-qrcode  {
  margin: auto;
  display: table;
}
</style>