var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_vm._v("Liste des Affiliations")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-10 p-8"},[_c('v-row',[_c('v-col',{attrs:{"lg":"9"}},[_c('v-text-field',{attrs:{"label":"Filtre"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Enregistré le","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.interval.date1),callback:function ($$v) {_vm.$set(_vm.interval, "date1", $$v)},expression:"interval.date1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.interval.date1),callback:function ($$v) {_vm.$set(_vm.interval, "date1", $$v)},expression:"interval.date1"}})],1)],1)],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AffiliateDetail',{attrs:{"statues":_vm.statues,"instanceaffiliate":_vm.editedItem}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.affiliatell,"sort-by":['id'],"hide-default-footer":"","sort-desc":true},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:"v-chip-light-bg text-white text-sm font-weight-semibold  text-capitalize",attrs:{"color":item.status.color}},[_vm._v(_vm._s(item.status.label))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.detailCourse)?_c('v-btn',{attrs:{"small":"","color":"primary","depressed":"","to":'/settings/affiliate/'+item.id}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]),_vm._v(" Consulter ")],1):_vm._e(),(_vm.detailCourse && _vm.verify(item, _vm.order))?_c('v-chip',{staticClass:"v-chip-light-bg text-xl text-white font-weight-semibold  text-uppercase",attrs:{"color":"#ffc107"}},[_vm._v("Colis Affecté")]):_vm._e(),(_vm.detailCourse && (_vm.order.affiliate_id != item.id))?_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.loadingAffectation,"disabled":_vm.disableAffectation,"small":""},on:{"click":function($event){return _vm.confirmAssign(item, _vm.order)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Affecter ")],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }