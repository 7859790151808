<template>
  <div>
    <v-card-title>Liste des Affiliations</v-card-title>
    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-10 p-8">
      <!-- search -->
      <v-row>
        <v-col lg="9">
          <v-text-field v-model="searchQuery" label="Filtre"></v-text-field>
        </v-col>
        <!-- v-col lg="2">
          <v-select
              label="Statut"
              v-model="search.status"
              :items="statues"
              item-value="id"
              item-text="label"
              @change="fetchItems"
          ></v-select>
        </v-col -->
        <v-col lg="3">
          <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="interval.date1"
                  label="Enregistré le"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="interval.date1"
                @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>
      <v-dialog
          v-model="dialog"
          max-width="500px"
      >
        <AffiliateDetail
            :statues="statues"
            :instanceaffiliate="editedItem" />
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
          :loading="loading"
          :headers="headers"
          :items="affiliatell"
          class="elevation-1"
          :sort-by="['id']"
          @page-count="pageCount = $event"
          hide-default-footer
          :sort-desc="true"
      >

        <template v-slot:item.status="{ item }">
          <v-chip
              :color="item.status.color"
              :class="`v-chip-light-bg text-white text-sm font-weight-semibold  text-capitalize`"
          >{{ item.status.label }}</v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
              v-if="!detailCourse"
              small
              color="primary"
              depressed
              :to="'/settings/affiliate/'+item.id"
          >
            <v-icon left>
              {{ icons.mdiEye }}
            </v-icon>
            Consulter
          </v-btn>
          <v-chip

              v-if="detailCourse && verify(item, order)"
              color="#ffc107"
              class="v-chip-light-bg text-xl text-white font-weight-semibold  text-uppercase"
          >Colis Affecté</v-chip>
          <v-btn
              :loading="loadingAffectation"
              :disabled="disableAffectation"
              v-if="detailCourse && (order.affiliate_id != item.id)"
              @click="confirmAssign(item, order)"
              small
              class="primary">
            <v-icon
                small
                class="mr-2"
            >
              mdi-plus
            </v-icon>
            Affecter
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>
<script>
const moonLanding = new Date();
import {Drequest} from "../../plugins/Drequest";
import AffiliateDetail from "../../components/AffiliateDetail";
import {
  mdiEye,
} from '@mdi/js'
export default {

  name: "affiliate-manager",
  components: {AffiliateDetail},
  props: ["user","detailCourse","order","arrival","departure"],
  data: () => ({
    icons: {
      mdiEye,
    },
    interval: {
      date1: moonLanding.getFullYear() + "-01-01",
      date2: moonLanding.getFullYear() + "-12-31"
    },
    searchQuery: "",
    menu2: false,
    menu3: false,
    loading: false,
    disableAffectation: false,
    loadingAffectation: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Nom',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: 'Téléphone', value: 'phonenumber'},
      {text: 'Arrivé', value: 'arrival'},
      {text: 'Date départ', value: 'date_from'},
      {text: 'Date Arrivée', value: 'date_to'},
      {text: 'Status', value: 'status'},
      {text: 'Enregistré le', value: 'created_at'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    items: [],
    statues: [],
    affiliatell:[],
    editedIndex: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
    // tab: {},
    transporter: {},
  }),
  methods: {
    verify(item, order){
      if(order.affiliate_id == item.id){
        this.disableAffectation = true;
        return true
      }
      return false;
    },
    async confirmAssign(item, order) {
      this.loadingAffectation = true
      await Drequest.api("update.order?id="+order.id).data({"order":{"affiliate.id": item.affiliate_id}}).raw((response)=>{
        // eslint-disable-next-line no-empty
        if(response.success){
          Drequest.api("update.disponibility?id="+item.id).data({"disponibility":{"statusKey": "busy"}}).raw((response)=>{
              console.log(response);
          });
        }
      })
      this.loadingAffectation = false
    },
    confirmUnassign(order) {
      this.loadingAffectation = true
      Drequest.api("update.order?id="+order.id).data({"order":{"affiliate.id": null}}).raw((response)=>{
        console.log(response);
      })
      this.loadingAffectation = false
    },
    initialize() {
      this.loading = true
      Drequest.api("disponibility.listbydestination?arrival="+this.arrival+"&departure="+this.departure)
          .raw((response) => {
            this.loading = false;
            let disponibility = [];
            response.disponibility.forEach((item) => {
              disponibility.push({id: item.id, affiliate_id: item.affiliate.id, phonenumber: item.affiliate.user.phonenumber, status: item.status, name:item.affiliate.user.firstname, date_from: item.date_from, date_to: item.date_to, created_at: item.created_at, arrival: item.arrival, departure: item.departure});
            })
            this.affiliatell = disponibility;
          })

    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    copyItem(item) {
      this.editedItem = {
        min:parseFloat(item.max)+1
      }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      Drequest.api("delete.price-interval?id="+this.editedItem.id).raw((response)=>{
        console.log(response);
        this.items.splice(this.editedIndex, 1)
        this.closeDelete()

      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Drequest.api("update.affiliate?id="+this.editedItem.id).data({price_interval:this.editedItem}).raw((response)=>{
          console.log(response);
          this.items[this.editedIndex] = response.price_interval
          this.close()
        })
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  beforeMount() {
    let transporter = this.$store.state.transporter;
    this.initialize(transporter.id);
    this.transporter = transporter;
  },
  mounted() {
    //this.tab = this.items[0];
  }
}
</script>
